import React from "react";
import { Column } from "./Flex";
import { TableEmptyState } from "./Table";
import { useTranslation } from "react-i18next";

export const ErrorState = (props: { title?: string; body?: string }) => {
  const { t } = useTranslation("ErrorState");
  return (
    <Column grow={1} alignItems="center" justifyContent="center">
      <TableEmptyState
        title={props.title ?? t("Something went wrong")}
        body={
          props.body ??
          t(
            "Please try again, or contact Teamworks support if the issue persists",
          )
        }
      />
    </Column>
  );
};
