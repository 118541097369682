import React, { lazy, Suspense, useCallback, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { initLocalisation } from "./helpers/localise";
import { LoadingScreen } from "./components/LoadingScreen";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, useOktaAuth } from "@okta/okta-react";
import { config } from "./config";
import { useTranslation } from "react-i18next";
import { Layout } from "./components/Layout";
import { SnacksProvider } from "./providers/SnacksProvider";
import { DialogProvider } from "./providers/DialogProvider";
import { RelayProvider } from "./providers/RelayProvider";
import { ScreenSizeProvider } from "./providers/ScreenSizeProvider";
import { CurrentProvider } from "./providers/CurrentProvider";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorScreen } from "./screens/ErrorScreen";
import { errorReporting } from "./helpers/errorReporting";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider } from "./providers/ThemeProvider";
import { AuthProvider, useAuth } from "./providers/AuthProvider";
import { analytics } from "./helpers/analytics";
import { ID } from "./helpers/types";
import { ProductToursProvider } from "./providers/ProductTourProvider";
import {
  InitialSearchParamsProvider,
  useInitialSearchParams,
} from "./providers/InitialSearchParamsProvider";
import { PreviewsProvider } from "./providers/PreviewsProvider";
import { ErrorState } from "./components/ErrorState";

const LoginScreen = lazy(() =>
  import("./screens/LoginScreen").then(({ LoginScreen }) => ({
    default: LoginScreen,
  })),
);

const AppSwitcherScreen = lazy(() =>
  import("./screens/AppSwitcherScreen").then(({ AppSwitcherScreen }) => ({
    default: AppSwitcherScreen,
  })),
);

const ConfigsScreen = lazy(() =>
  import("./screens/ConfigsScreen").then(({ ConfigsScreen }) => ({
    default: ConfigsScreen,
  })),
);

const NotFoundScreen = lazy(() =>
  import("./screens/NotFoundScreen").then(({ NotFoundScreen }) => ({
    default: NotFoundScreen,
  })),
);

const ThemesScreen = lazy(() =>
  import("./screens/ThemesScreen").then(({ ThemesScreen }) => ({
    default: ThemesScreen,
  })),
);

const AnnouncementsScreen = lazy(() =>
  import("./screens/AnnouncementsScreen").then(({ AnnouncementsScreen }) => ({
    default: AnnouncementsScreen,
  })),
);

const DevicesScreen = lazy(() =>
  import("./screens/Devices/DevicesScreen").then(({ DevicesScreen }) => ({
    default: DevicesScreen,
  })),
);

const DevicesTab = lazy(() =>
  import("./screens/Devices/DevicesTab").then(({ DevicesTab }) => ({
    default: DevicesTab,
  })),
);

const DeviceGroupsTab = lazy(() =>
  import("./screens/Devices/DeviceGroupsTab").then(({ DeviceGroupsTab }) => ({
    default: DeviceGroupsTab,
  })),
);

const PlaylistsScreen = lazy(() =>
  import("./screens/PlaylistsScreen").then(({ PlaylistsScreen }) => ({
    default: PlaylistsScreen,
  })),
);

const SettingsScreen = lazy(() =>
  import("./screens/Settings/SettingsScreen").then(({ SettingsScreen }) => ({
    default: SettingsScreen,
  })),
);

const PermissionGroupsTab = lazy(() =>
  import("./screens/Settings/PermissionGroupsTab").then(
    ({ PermissionGroupsTab }) => ({
      default: PermissionGroupsTab,
    }),
  ),
);

const UsersTab = lazy(() =>
  import("./screens/Settings/UsersTab").then(({ UsersTab }) => ({
    default: UsersTab,
  })),
);

const ManageIpAddressesTab = lazy(() =>
  import("./screens/Settings/ManageIpAddressesTab").then(
    ({ ManageIpAddressesTab }) => ({
      default: ManageIpAddressesTab,
    }),
  ),
);

initLocalisation();
errorReporting.initialize();

const SecureRoute = () => {
  const auth = useAuth();
  const { oktaAuth } = useOktaAuth();
  const { axleRefreshToken, axleToken } = useInitialSearchParams();

  const handleLogin = () => {
    oktaAuth.signInWithRedirect({
      originalUri: window.location
        .toString()
        .replace(window.location.host, "")
        .replace(`${window.location.protocol}//`, ""),
    });
  };

  useEffect(() => {
    if (auth.status === "authenticated" && auth.region === "us") {
      analytics.initialize();
      analytics.identify(
        auth.activeUserProfile.profileId as ID,
        {
          fullName: auth.activeUserProfile.profileName,
        },
        {
          email: auth.activeUserProfile.email,
          firstName: auth.activeUserProfile.firstName,
          lastName: auth.activeUserProfile.lastName,
        },
        {
          id: auth.activeUserProfile.orgId,
          name: auth.activeUserProfile.orgName,
        },
      );
    }
  }, [auth]);

  // Force auth with axleToken
  if (axleToken != null) {
    return <AppSwitcherScreen token={axleToken} />;
  }

  // Auth with axleRefreshToken if not authenticated
  if (auth.status !== "authenticated" && axleRefreshToken != null) {
    return <AppSwitcherScreen token={axleRefreshToken} />;
  }

  if (auth.status !== "authenticated") {
    return (
      <ScreenSizeProvider>
        <LoginScreen handleLogin={handleLogin} />
      </ScreenSizeProvider>
    );
  }

  return (
    <RelayProvider
      orgId={auth.activeUserProfile.orgId}
      profileId={auth.activeUserProfile.profileId}
    >
      <PreviewsProvider>
        <ScreenSizeProvider>
          <ProductToursProvider>
            <CurrentProvider>
              <ThemeProvider>
                <DialogProvider>
                  <SnacksProvider>
                    <DndProvider backend={HTML5Backend}>
                      <ErrorBoundary fallback={<ErrorScreen />}>
                        <Layout>
                          <Outlet />
                        </Layout>
                      </ErrorBoundary>
                    </DndProvider>
                  </SnacksProvider>
                </DialogProvider>
              </ThemeProvider>
            </CurrentProvider>
          </ProductToursProvider>
        </ScreenSizeProvider>
      </PreviewsProvider>
    </RelayProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <Outlet />,
    errorElement: <ErrorScreen />,
    children: [
      {
        path: "/login/callback",
        element: <LoginCallback />,
      },
      {
        path: "/auth/axle",
        element: <AppSwitcherScreen />,
      },
      {
        element: <SecureRoute />,
        children: [
          {
            path: "/login",
            element: <LoginScreen />,
          },
          {
            path: "/",
            element: <DevicesScreen />,
            children: [
              {
                path: "/",
                element: <DevicesTab />,
              },
            ],
          },
          {
            path: "/devices",
            element: <DevicesScreen />,
            children: [
              {
                path: "/devices",
                element: <DevicesTab />,
              },
              {
                path: "/devices/groups",
                element: <DeviceGroupsTab />,
              },
            ],
          },
          {
            path: "/configs",
            element: <ConfigsScreen />,
          },
          {
            path: "/themes",
            element: <ThemesScreen />,
          },
          {
            path: "/announcements",
            element: <AnnouncementsScreen />,
          },
          {
            path: "/playlists",
            element: <PlaylistsScreen />,
          },
          {
            path: "/settings",
            element: <SettingsScreen />,
            ErrorBoundary: () => <ErrorState />,
            children: [
              {
                path: "/settings",
                element: <UsersTab />,
              },
              {
                path: "/settings/permission-groups",
                element: <PermissionGroupsTab />,
              },
              {
                path: "/settings/ip-addresses",
                element: <ManageIpAddressesTab />,
              },
            ],
          },
          {
            path: "/*",
            element: <NotFoundScreen />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  const { t } = useTranslation("App");

  const oktaAuth = new OktaAuth({
    issuer: config.oktaIssuerUrl,
    clientId: config.oktaClientId,
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    scopes: ["openid", "profile", "email"],
  });

  const restoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri = "/") => {
      window.location.replace(
        toRelativeUrl(originalUri || "/", window.location.origin),
      );
    },
    [],
  );

  // https://github.com/okta/okta-react/issues/227
  useEffect(() => {
    return () => (oktaAuth.options.restoreOriginalUri = undefined);
  }, [oktaAuth.options]);

  return (
    <ErrorBoundary fallback={<ErrorScreen />}>
      <InitialSearchParamsProvider>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Suspense fallback={<LoadingScreen message={t("Loading")} />}>
            <AuthProvider>
              <RouterProvider router={router} />
            </AuthProvider>
          </Suspense>
        </Security>
      </InitialSearchParamsProvider>
    </ErrorBoundary>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />,
);
