import React, { forwardRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { TextField, TextFieldProps } from "@mui/material";
import { colors } from "../helpers/theme";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

type TextInputProps = TextFieldProps & {
  maxLength: number;
};

const useClasses = makeStyles({
  input: {
    backgroundColor: colors.white,
  },
});

export const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  function TextInput({ className, inputProps, autoComplete, ...props }, ref) {
    const classes = useClasses();
    return (
      <TextField
        ref={ref}
        {...props}
        autoComplete={autoComplete ?? "off"}
        className={classNames([classes.input, className])}
        inputProps={{
          maxLength: props.maxLength,
          ...inputProps,
        }}
      />
    );
  },
);
