import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { useDebounced } from "../hooks/useDebounced";
import { ID } from "../helpers/types";
import { useTranslation } from "react-i18next";

type TableContext = {
  title: string;
  setTitle: (title: string) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  querySearchText: string | null;
  selectedIds: ID[];
  setSelectedIds: (ids: ID[]) => void;
  toggleSelected: (id: ID) => void;
};

const Context = createContext<TableContext>(null!);

export const TableProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("TableProvider");
  const [title, setTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounced(searchText, 300).trim();
  const [selectedIds, setSelectedIds] = useState<ID[]>([]);

  const titleText = [
    title,
    selectedIds.length === 0
      ? undefined
      : t("{{count}} Selected", { count: selectedIds.length }),
  ]
    .filter(Boolean)
    .join(" | ");

  return (
    <Context.Provider
      value={{
        title: titleText,
        setTitle,
        searchText,
        setSearchText,
        querySearchText:
          debouncedSearchText.length === 0 ? null : debouncedSearchText,
        selectedIds,
        setSelectedIds,
        toggleSelected: (id) => {
          if (selectedIds.includes(id)) {
            setSelectedIds((selected) => selected.filter((i) => i !== id));
          } else {
            setSelectedIds([...selectedIds, id]);
          }
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useTableContext = () => {
  const ctx = useContext(Context);
  if (ctx == null) {
    throw new Error("useTableContext: context not ready");
  }
  return ctx;
};
