import { type Localisation } from "./en";

export const dutch: Localisation = {
  dateFormats: {
    datePickerLocale: "nl",
    timeFormat: "HH:mm",
    dayMonthYearFormat: "do MMMM yyyy", // 11 January 1988
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 12:30
  },
  App: {
    "Digital Displays": "Digitale Displays",
    Loading: "Laden",
    "Checking authentication": "Authenticatie controleren",
    "Redirecting to login": "Doorverwijzen naar inloggen",
    "Sign in with Teamworks": "Inloggen met Teamworks",
    Initalizing: "Initialiseren",
    "Verifing authentication": "Verificatie van authenticatie",
    Redirecting: "Omleiden",
    "Welcome back!": "Welkom terug!",
    "Login to get started": "Log in om te beginnen",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
  },
  Layout: {
    "Checking authentication": "Authenticatie controleren",
    Dashboard: "Dashboard",
    Configurations: "Configuraties",
    Devices: "Apparaten",
    Themes: "Thema's",
    Settings: "Instellingen",
    Announcements: "Announcements",
    "Organisation settings": "Organisatie-instellingen",
    "Teamworks Help": "Teamworks Help",
    "Teamworks Apps": "Teamworks Apps",
    "Privacy policy": "Privacybeleid",
    Logout: "Uitloggen",
    "Loading, please wait...": "Laden, even geduld aub...",
    Users: "Gebruikers",
    "Switch organisation": "Organisatie wijzigen",
    "Signing you out": "Uitloggen",
    "Open Menu": "Menu Openen",
    "Close Menu": "Menu Sluiten",
    Playlists: "Afspeellijsten",
  },
  ConfigsScreen: {
    "{{count}} Configuration": "{{count}} Configuratie",
    "{{count}} Configuration_plural": "{{count}} Configuraties",
    "New configuration": "Nieuwe configuratie",
    "Configurations allow you to customise the look and content of your displays.":
      "Configuraties stellen u in staat om het uiterlijk en de inhoud van uw displays aan te passen.",
    "Loading configurations": "Configuraties laden",
    Configurations: "Configuraties",
    Name: "Naam",
    Tags: "Tags",
    Template: "Sjabloon",
    Theme: "Thema",
    "Device count": "Aantal apparaten",
    "Created at": "Aangemaakt op",
    "No configurations have been setup yet":
      "Er zijn nog geen configuraties ingesteld",
    "No configurations found for '{{searchText}}'":
      "Geen configuraties gevonden voor '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Displays stellen u in staat om geplande evenementen en media te casten naar elk apparaat met een internetbrowser",
    "Create one now": "Maak er nu een",
    "Create configuration": "Configuratie maken",
    "Add custom videos, YouTube videos and images":
      "Voeg aangepaste video's, YouTube-video's en afbeeldingen toe",
    "Filter calendar to present a live schedule":
      "Filter de agenda om een live schema weer te geven",
    "Cycle through important updates on cards":
      "Doorloop belangrijke updates op kaarten",
    "Includes team crest, time and date":
      "Inclusief teamembleem, tijd en datum",
    "Display a schedule over multiple days":
      "Toon een schema over meerdere dagen",
    "Display daily schedule for different teams and groups.":
      "Toon dagelijks schema voor verschillende teams en groepen.",
    "Configuration deleted": "Configuratie verwijderd",
    "Are you sure you want to delete this configuration?":
      "Weet u zeker dat u deze configuratie wilt verwijderen?",
    "Delete configuration": "Configuratie verwijderen",
    "Fetching configuration": "Configuratie ophalen",
    "Digital Displays URL": "Digitale weergave-URL",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "U kunt deze hardcoded URL gebruiken om te integreren met systemen die onze QR-code installatie niet ondersteunen.",
    "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.":
      "Neem contact op met uw Teamworks-beheerder om ervoor te zorgen dat uw IP-adressen toegang hebben tot de link voor beveiliging.",
    "Something went wrong": "Er is iets misgegaan",
    "Edit configuration": "Configuratie bewerken",
    "Get hardcoded URL": "Hardcoded URL ophalen",
    "Includes background image from theme":
      "Inclusief achtergrondafbeelding van thema",
    "Last active at {{lastActiveAt}}": "Laatst actief om {{lastActiveAt}}",
    "Loading screen devices": "Schermapparaten laden",
    "Search configurations...": "Configuraties zoeken...",
    "Save changes": "Wijzigingen opslaan",
    "Last updated": "Laatst bijgewerkt",
    "Clear template": "Sjabloon wissen",
    "No template selected": "Geen sjabloon geselecteerd",
    "Hardcoded playlists and configurations are being depreciated, please use virtual devices.":
      "Gecodeerde afspeellijsten en configuraties zijn verouderd, gebruik alstublieft virtuele apparaten.",
    "Just like hardcoded playlists and configurations, a virtual device will provide you with a URL to be used with systems which do not support the QR setup.":
      "Net als gecodeerde afspeellijsten en configuraties, zal een virtueel apparaat u voorzien van een URL die kan worden gebruikt met systemen die de QR-configuratie niet ondersteunen.",
    "With a virtual device, you will be able to manage the active playlist or configuration without needing to update the third party system.":
      "Met een virtueel apparaat kunt u de actieve afspeellijst of configuratie beheren zonder het systeem van derden te hoeven bijwerken.",
  },
  DashboardScreen: { Dashboard: "Dashboard" },
  DevicesScreen: {
    Devices: "Apparaten",
    Groups: "Groepen",
    "{{count}} Device": "{{count}} Apparaat",
    "{{count}} Device_plural": "{{count}} Apparaten",
    "New device": "Nieuw apparaat",
    "Devices are the physical displays you have linked to your account.":
      "Apparaten zijn de fysieke beeldschermen die u aan uw account heeft gekoppeld.",
    "No devices have been setup yet": "Er zijn nog geen apparaten ingesteld",
    "No devices found for '{{searchText}}'":
      "Geen apparaten gevonden voor '{{searchText}}'",
    "Device name": "Toestelnaam",
    Facility: "Faciliteit",
    Configuration: "Configuratie",
    "Facility not set": "Faciliteit niet ingesteld",
    "Config not set": "Configuratie niet ingesteld",
    "Create device": "Apparaat aanmaken",
    "Update device": "Apparaat bijwerken",
    "Screen device updated": "Schermapparaat bijgewerkt",
    "Something went wrong": "Er is iets fout gegaan",
    "Adding new display device": "Nieuw weergaveapparaat toevoegen",
    addNewDeviceDescription:
      "Om een nieuw apparaat toe te voegen, ga naar <0>{{url}}</0> op het apparaat dat je wilt koppelen.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Je krijgt een QR-code te zien die je met je camera kunt scannen.",
    "Start scanning": "Begin met scannen",
    Ok: "OK",
    "Are you sure you want to delete this device?":
      "Weet je zeker dat je dit apparaat wilt verwijderen?",
    "Delete device": "Verwijder apparaat",
    "Fetching devices": "Apparaten ophalen",
    Timezone: "Tijdzone",
    "Display name is required": "Weergavenaam is verplicht",
    "Display name must be at least 3 characters long":
      "Weergavenaam moet minstens 3 tekens lang zijn",
    "Device setup successful": "Apparaat succesvol ingesteld",
    "Switch organisation": "Organisatie wijzigen",
    "Search devices...": "Apparaten zoeken...",
    "Device not found": "Apparaat niet gevonden",
    "It may have been deleted, or you do not have permission to view it.":
      "Het kan zijn verwijderd, of je hebt geen toestemming om het te bekijken.",
    "Loading device": "Apparaat laden",
    "Last updated": "Laatst bijgewerkt",
    config: "Configuratie",
    playlist: "Afspeellijst",
    "Playlist not set": "Afspeellijst niet ingesteld",
    "Device mode": "Apparaatmodus",
    "Should this device display a configuration or a playlist?":
      "Moet dit apparaat een configuratie of een afspeellijst weergeven?",
    "Bulk actions": "Bulkacties",
    "Edit selected": "Geselecteerde bewerken",
    "Should these devices display a configuration or a playlist?":
      "Moeten deze apparaten een configuratie of een afspeellijst weergeven?",
    "Delete selected": "Selectie verwijderen",
    "Delete {{count}} devices": "Verwijder {{count}} apparaten",
    "Are you sure you want to delete these devices?":
      "Weet u zeker dat u deze apparaten wilt verwijderen?",
    "Devices deleted": "Apparaten verwijderd",
    "Screen devices updated": "Schermapparaten bijgewerkt",
    "Update {{count}} devices": "Update {{count}} apparaten",
    "Device deleted": "Apparaat verwijderd",
    "Select all": "Selecteer alles",
    "Deselect all": "Deselecteer alles",
    "Create virtual device": "Virtueel apparaat aanmaken",
    "Virtual device URL": "URL van virtueel apparaat",
    "This URL can be used to integrate with systems that do not support our QR code setup.":
      "Deze URL kan worden gebruikt om te integreren met systemen die onze QR-code-instelling niet ondersteunen.",
    "Note: This URL will only be accessable from networks with an approved IP address.":
      "Opmerking: Deze URL is alleen toegankelijk vanuit netwerken met een goedgekeurd IP-adres.",
    or: "of",
    "Provides a hardcoded URL, for use with systems which do not support the QR setup":
      "Biedt een vastgecodeerde URL voor gebruik met systemen die de QR-configuratie niet ondersteunen",
    "You can now create virtual devices for use on systems which do not support the QR setup. This update replaces hardcoded playlist and configuration URL’s.":
      "U kunt nu virtuele apparaten aanmaken voor gebruik op systemen die de QR-configuratie niet ondersteunen. Deze update vervangt de hardcoded URL’s voor afspeellijsten en configuraties.",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Oeps! Het lijkt erop dat je verdwaald bent.",
    "Return to dashboard": "Keer terug naar het dashboard",
  },
  ThemeScreen: {
    "New theme": "Nieuw thema",
    "Themes allow you to customise the colours of your configurations.":
      "Thema's stellen je in staat om de kleuren van je configuraties aan te passen.",
    Themes: "Thema",
    "You don't have any themes yet": "Je hebt nog geen thema's",
    "Themes allow you to customise the colours used by your templates":
      "Thema's stellen je in staat om de kleuren aan te passen die worden gebruikt door je sjablonen",
    "Create one now": "Maak er nu een aan",
    "Theme name": "Thema naam",
    "Last updated": "Laatst bijgewerkt",
    "Create theme": "Thema aanmaken",
    "Update theme": "Thema bijwerken",
    background: "Achtergrond",
    backgroundText: "Achtergrondtekst",
    accent: "Accent",
    accentText: "Accenttekst",
    announcementBar: "Aankondigingsbalk",
    announcementBarText: "Tekst op de aankondigingsbalk",
    "Invalid hex code": "Ongeldige hexadecimale code",
    "New theme created": "Nieuw thema gemaakt",
    "Theme updated": "Thema bijgewerkt",
    "Screen theme deleted": "Schermthema verwijderd",
    "Something went wrong": "Er is iets fout gegaan",
    "Are you sure you want to delete this theme?":
      "Weet je zeker dat je dit thema wilt verwijderen?",
    "Delete theme": "Thema verwijderen",
    "Loading themes": "Thema's laden",
    "Upload logo": "Logo uploaden",
    "Remove logo": "Logo verwijderen",
    "Upload background image": "Achtergrondafbeelding uploaden",
    "Remove image": "Afbeelding verwijderen",
    "Theme not found": "Thema niet gevonden",
    "It may have been deleted, or you do not have permission to view it":
      "Het kan zijn verwijderd, of je hebt geen toestemming om het te bekijken",
    "Loading theme": "Thema laden",
    "Search themes...": "Thema's zoeken...",
    "{{count}} Theme": "{{count}} Thema",
    "{{count}} Theme_plural": "{{count}} Thema's",
    "What's a theme?": "Wat is een thema?",
    "Themes allow you to customise the appearance of your configurations":
      "Thema's stellen je in staat om het uiterlijk van je configuraties aan te passen",
    "Clicking this button will begin the process of creating a new one.":
      "Door op deze knop te klikken, begint het proces van het maken van een nieuw thema.",
    "Give your theme a memorable name": "Geef je thema een gedenkwaardige naam",
    "This will help you find it later when assigning it to a configuration.":
      "Dit helpt je om het later te vinden wanneer je het toewijst aan een configuratie.",
    "Upload a logo (optional)": "Een logo uploaden (optioneel)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "Als je geen logo uploadt, verschijnt er een Teamworks-logo in plaats daarvan.",
    "Upload a background image (optional)":
      "Een achtergrondafbeelding uploaden (optioneel)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Sommige configuraties maken gebruik van een achtergrondafbeelding. Als er geen afbeelding is geüpload, wordt de hieronder geselecteerde achtergrondkleur gebruikt.",
    "Customise the colours you want to use":
      "Pas de kleuren aan die je wilt gebruiken",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "De 'tekst'-opties moeten een contrasterende kleur hebben, zodat ze gemakkelijk te lezen zijn.",
    "Once you are ready, save the theme by clicking this button":
      "Zodra je klaar bent, sla je het thema op door op deze knop te klikken",
    "Your theme will then be available to be selected when managing a configuration.":
      "Je thema is dan beschikbaar om te worden geselecteerd bij het beheren van een configuratie.",
    "Theme contrast": "Theme contrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Sommige inhoud verschijnt in containers die semi-transparant zijn en over de door jou gekozen achtergrondkleur heen worden gelegd.",
    "Would you like to lighten or darken your background?":
      "Wilt u uw achtergrond lichter of donkerder maken?",
    Dark: "Donker",
    Light: "Licht",
    "Theme preview": "Themavoorbeeld",
    Close: "Dichtbij",
    Preview: "Voorbeeld",
    "No themes found for '{{searchText}}'":
      "Geen thema's gevonden voor '{{searchText}}'",
  },
  ScreenTemplateNames: {
    "L shape with media": "L-vorm met media",
    "Media only": "Alleen media",
    "Schedule only": "Alleen schema",
    "Multi day schedule": "Meerdaags schema",
    "Multi column schedule": "Schema met meerdere kolommen",
    Basic: "Basis",
  },
  DialogProvider: {
    Cancel: "Annuleren",
    Confirm: "Bevestigen",
    Close: "Sluiten",
    Ok: "Ok",
    "Close without saving?": "Sluiten zonder op te slaan?",
    "Close without saving": "Sluiten zonder op te slaan",
    "Any unsaved changes will be lost":
      "Niet-opgeslagen wijzigingen gaan verloren",
    "Back to form": "Terug naar formulier",
  },
  ConfigurationPicker: {
    Configuration: "Configuratie",
    "No configurations found for '{{searchText}}'":
      "Geen configuraties gevonden voor '{{searchText}}'",
    "No configurations found": "Geen configuraties gevonden",
    "No configuration selected": "Geen configuratie geselecteerd",
    "Clear configuration": "Configuratie wissen",
    "Searching configurations": "Configuraties zoeken",
  },
  ThemePicker: {
    Theme: "Thema",
    "Clear theme": "Thema wissen",
    "Screen theme": "Schermthema",
    "No theme selected": "Geen thema geselecteerd",
    "No themes found matching '{{searchText}}'":
      "Geen thema's gevonden die overeenkomen met '{{searchText}}'",
    "No themes found": "Geen thema's gevonden",
    "Loading themes...": "Thema's laden...",
  },
  MarkdownField: {
    Preview: "Voorbeeld",
    Remove: "Verwijderen",
    "Nothing to preview": "Niets om vooraf te bekijken",
    Write: "Schrijven",
  },
  ErrorScreen: {
    "Something went wrong": "Er is iets misgegaan",
    "Sorry for the inconvience, our engineers have been notified and will be working on a fix":
      "Onze excuses voor het ongemak, onze ingenieurs zijn op de hoogte gebracht en zullen aan een oplossing werken",
    "If this issue persists, please contact Teamworks customer support":
      "Als dit probleem aanhoudt, neem dan contact op met de klantondersteuning van Teamworks",
    Reload: "Opnieuw laden",
    "Customer support": "Klantondersteuning",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Organisaties ophalen",
    "Select an organisation": "Selecteer een organisatie",
    "Switching profile": "Profiel wisselen",
  },
  TimeZonePicker: {
    Timezone: "Tijdzone",
  },
  MatchesDrawer: {
    "Add match": "Voeg wedstrijd toe",
    "Fetching event": "Evenement ophalen",
    "Event title": "Titel van het evenement",
    "Starts at": "Begint om",
    "Create event": "Evenement aanmaken",
  },
  FileUploadField: {
    "Upload file": "Bestand uploaden",
    "Remove file": "Bestand verwijderen",
  },
  HubProfilePicker: {
    Search: "Zoekopdracht",
    "Clear search text": "Zoektekst wissen",
    "Search text too short": "Zoektekst te kort",
    "Please add at least 3 characters to begin a search":
      "Voeg alstublieft ten minste 3 tekens toe om een zoekopdracht te starten",
    "No calendars found": "Geen kalenders gevonden",
    "We couldn't find any calendars matching '{{searchText}}'":
      "We konden geen kalenders vinden die overeenkomen met '{{searchText}}'",
    "Search...": "Zoekopdracht...",
    "Confirm selection": "Bevestig selectie",
    Close: "Dichtbij",
    "Add Teamworks calendars": "Teamworks-kalenders toevoegen",
    "Loading profiles": "Profielen laden",
    "Search text must have at least 3 characters":
      "Zoektekst moet minimaal 3 tekens bevatten",
    "No results found": "Geen resultaten gevonden",
    "Calendar details": "Kalenderdetails",
    "Remove calendar": "Kalender verwijderen",
    "Are you sure you want to remove this calendar?":
      "Weet je zeker dat je deze kalender wilt verwijderen?",
    "No calendars added": "Geen kalenders toegevoegd",
    "Unknown profile": "Onbekend profiel",
    "You don't have access to this hub profile":
      "Je hebt geen toegang tot dit hub-profiel",
    "Your administrator may need to review your permission groups.":
      "Uw beheerder moet mogelijk uw permissiegroepen herzien.",
    "Show {{count}} more": "Toon {{count}} meer",
  },
  ScreenEventsPicker: {
    "Loading events": "Evenementen laden",
    "Event countdown": "Aftellen van evenement",
    "Create event": "Evenement aanmaken",
    "Update event": "Evenement bijwerken",
    "Event title": "Titel van het evenement",
    "Starts at": "Begint om",
    "Add opponent badge": "Tegenstander-badge toevoegen",
    "Remove opponent badge": "Tegenstander-badge verwijderen",
    "Delete event": "Evenement verwijderen",
    "Are you sure you want to delete this event?":
      "Weet je zeker dat je dit evenement wilt verwijderen?",
    "Delete now": "Nu verwijderen",
    "Opponent badge position": "Positie van het insigne van de tegenstander",
    "Should the opponent badge be on the left or right?":
      "Moet het insigne van de tegenstander links of rechts zijn?",
    "i.e are you playing home or away?": "d.w.z. speel je thuis of uit?",
    "Edit event": "Bewerk evenement",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Configuratie laden",
    "Number of days the schedule should span. Max 5":
      "Aantal dagen dat het schema moet bestrijken. Max 5",
    "Number of days": "Aantal dagen",
    "Delete configuration": "Configuratie verwijderen",
    "Are you sure you want to delete this configuration?":
      "Weet je zeker dat je deze configuratie wilt verwijderen?",
    "Configuration deleted": "Configuratie verwijderd",
    "Something went wrong": "Er is iets misgegaan",
    "Save changes": "Wijzigingen opslaan",
    "Configuration updated": "Configuratie bijgewerkt",
    bottom_content_cards: "Kaarten",
    bottom_content_cards_desc:
      "Selecteer kaarten om tekst of afbeeldingen toe te voegen",
    media_content: "Media",
    media_content_desc: "Selecteer uit video, afbeeldingen en YouTube-inhoud",
    multi_column_schedule: "Meerkolomschema",
    multi_column_schedule_desc:
      "Selecteer Teamworks-kalenders om op het scherm weer te geven",
    multi_day_schedule: "Meerdaags schema",
    multi_day_schedule_desc:
      "Selecteer Teamworks-kalenders om op het scherm weer te geven",
    right_schedule: "Schema",
    right_schedule_desc:
      "Selecteer Teamworks-kalenders om op het scherm weer te geven",
    "%future added value": "Onbekend onderdeel",
    "%future added value_desc": "Onbekend onderdeel",
    "Configuration name": "Configuratienaam",
    "Add event countdown": "Voeg evenement aftellen toe",
    "A countdown will be displayed until the event starts":
      "Er wordt een aftelling weergegeven tot het evenement begint",
    "Not saved": "Niet opgeslagen",
    "Please fix any errors and try again":
      "Corrigeer eventuele fouten en probeer het opnieuw",
    "Give the configuration a name": "Geef de configuratie een naam",
    "Try to give it a relevant name, to help you quickly find it":
      "Probeer het een relevante naam te geven, zodat je het snel kunt vinden",
    "Pick a theme": "Kies een thema",
    "The theme will set colours and logo for your configuration":
      "Het thema stelt de kleuren en het logo voor je configuratie in",
    "Add an event (optional)": "Voeg een evenement toe (optioneel)",
    "A countdown will be displayed on the configuration until the event starts":
      "Er wordt een aftelling weergegeven op de configuratie tot het evenement begint",
    "Add a Teamworks schedule": "Voeg een Teamworks-schema toe",
    "Upload media": "Upload media",
    "Add cards (optional)": "Voeg kaarten toe (optioneel)",
    "Add multiple Teamworks schedules": "Voeg meerdere Teamworks-schema's toe",
    "Unknown component": "Onbekend component",
    "Events from the selected profiles will be shown on the configuration":
      "Evenementen van de geselecteerde profielen worden weergegeven in de configuratie",
    "You can upload images, videos or add YouTube videos":
      "Je kunt afbeeldingen, video's uploaden of YouTube-video's toevoegen",
    "The configuration will rotate through the cards one at a time":
      "De configuratie zal de kaarten één voor één doorlopen",
    "Each schedule will have its own column on the configuration":
      "Elk schema heeft zijn eigen kolom in de configuratie",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Een meerdaags schema toont de evenementen van de geselecteerde profielen over meerdere dagen",
    "Save the configuration": "Configuratie opslaan",
    "Once you are happy with your updates, save the changes":
      "Zodra je tevreden bent met je updates, sla je de wijzigingen op",
    "Take a tour": "Maak een rondleiding",
    "Hide past events": "Verberg afgelopen evenementen",
    "Announcements with matching tags will be displayed on screen":
      "Aankondigingen met overeenkomende tags worden op het scherm weergegeven",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Aankondigingstekst is vereist",
    "Announcements updated": "Aankondigingen bijgewerkt",
    "Something went wrong": "Er is iets fout gegaan",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Met aankondigingen kunt u belangrijke informatie plaatsen die op alle apparaten in banners kan worden weergegeven.",
    Announcements: "Aankondigingen",
    "Loading announcements": "Aankondigingen laden",
    "Manage announcements": "Beheer aankondigingen",
    "Announcements are displayed on all screens, at all times":
      "Aankondigingen worden te allen tijde op alle schermen weergegeven",
    "No announcements yet": "Nog geen aankondigingen",
    "New announcement": "Nieuwe aankondiging",
    "Save announcement": "Aankondiging opslaan",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Let op: aankondigingen moeten uit één regel bestaan. Nieuwe regels worden genegeerd.",
    "Delete announcement": "Aankondiging verwijderen",
    "Are you sure you want to remove this annoucement?":
      "Weet u zeker dat u deze aankondiging wilt verwijderen?",
    "{{count}} Announcement": "{{count}} Aankondiging",
    "{{count}} Announcement_plural": "{{count}} Aankondigingen",
    Content: "Inhoud",
    "Last updated": "Laatst bijgewerkt",
    "Display mode": "Weergavemodus",
    "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?":
      "Moet de aankondiging op alle afspeellijsten en configuraties worden weergegeven, of alleen op die met overeenkomende tags?",
    "Display on all": "Overal weergeven",
    Tagged: "Getagd",
    Tags: "Tags",
  },
  CardsFormField: {
    "Add card": "Kaart toevoegen",
    "Add image": "Afbeelding toevoegen",
    "Remove image": "Afbeelding verwijderen",
    "Remove card": "Kaart verwijderen",
    "Are you sure you want to remove this card?":
      "Weet je zeker dat je deze kaart wilt verwijderen?",
    "No cards added": "Geen kaarten toegevoegd",
    "Card title": "Kaarttitel",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Ongeldige YouTube-link, probeer het opnieuw",
    "File not supported, please upload an image or video":
      "Bestand wordt niet ondersteund, upload alstublieft een afbeelding of video",
    "Upload image/video": "Afbeelding/video uploaden",
    "Add YouTube": "YouTube toevoegen",
    "Add YouTube video": "YouTube-video toevoegen",
    "Video title": "Videotitel",
    "YouTube link": "YouTube-link",
    Image: "Afbeelding",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Er is iets misgegaan, bestand niet geüpload",
    "Cancel file upload?": "Upload annuleren?",
    "Are you sure you want to cancel this upload?":
      "Weet u zeker dat u deze upload wilt annuleren?",
    "Upload image": "Afbeelding uploaden",
    "Upload video": "Video uploaden",
    "Uploading {{uploadPct}}%": "Uploaden {{uploadPct}}%",
    "Upload complete": "Upload voltooid",
    Name: "Naam",
    "Number seconds image displays before moving to next media item":
      "Aantal seconden dat de afbeelding wordt weergegeven voordat deze naar het volgende mediabestand gaat",
    "Duration (seconds)": "Duur (seconden)",
    "Add image": "Afbeelding toevoegen",
    "Add video": "Video toevoegen",
    "Delete media": "Media verwijderen",
    "Are you sure you want to delete this media?":
      "Weet je zeker dat je deze media wilt verwijderen?",
    "Update duration": "Duur bijwerken",
    "Edit duration": "Duur bewerken",
    "Remove media": "Verwijder media",
    "No media added": "Geen media toegevoegd",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Kolom toevoegen",
    "Column title": "Kolomtitel",
    "Remove column": "Kolom verwijderen",
    "Are you sure you want to remove this column?":
      "Weet u zeker dat u deze kolom wilt verwijderen?",
    "Hide past events": "Verberg afgelopen evenementen",
  },
  AuthProvider: {
    "Fetching active users": "Actieve gebruikers ophalen",
    "Setting organisations": "Organisaties instellen",
    "Logging you in": "Inloggen",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Je hebt geen toegang tot Digitale Displays",
    "Please contact Teamworks customer support to request access":
      "Neem contact op met de klantondersteuning van Teamworks om toegang aan te vragen",
    "Sign out": "Uitloggen",
    "Customer support": "Klantondersteuning",
    Reload: "Opnieuw laden",
  },
  DeviceGroupsTab: {
    "Delete group": "Groep verwijderen",
    "Are you sure you want to delete this group?":
      "Weet je zeker dat je deze groep wilt verwijderen?",
    "Device group deleted successfully": "Apparaatgroep succesvol verwijderd",
    "Something went wrong": "Er is iets fout gegaan",
    "Device group created successfully": "Apparaatgroep succesvol aangemaakt",
    "Group updated": "Groep bijgewerkt",
    "New group": "Nieuwe groep",
    "Device groups allow you to group your display devices under one name.":
      "Met apparaatgroepen kunt u uw weergaveapparaten onder één naam groeperen.",
    "Device groups": "Apparaatgroepen",
    "{{count}} Device group": "{{count}} Apparaatgroep",
    "{{count}} Device group_plural": "{{count}} Apparaatgroepen",
    "Search groups...": "Groepen zoeken...",
    "Group name": "Groepsnaam",
    "Device count": "Aantal apparaten",
    "Fetching groups": "Groepen ophalen",
    "No groups": "Geen groepen",
    "Group name is required": "Groepsnaam is vereist",
    "Please select at least 2 devices": "Selecteer minimaal 2 apparaten",
    "Create group": "Groep aanmaken",
    "Update group": "Groep bijwerken",
    "Fetching devices...": "Apparaten ophalen...",
    "Select devices": "Selecteer apparaten",
    Devices: "Apparaten",
    Groups: "Groepen",
    "Number devices": "Aantal apparaten",
  },
  ErrorState: {
    "Something went wrong": "Er is iets misgegaan",
    "Please try again, or contact Teamworks support if the issue persists":
      "Probeer het opnieuw, of neem contact op met Teamworks support als het probleem blijft bestaan",
  },
  ProductToursProvider: {
    Back: "Terug",
    Next: "Volgende",
    Finish: "Voltooien",
  },
  Drawer: {
    Close: "Sluiten",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "URL-parameters initialiseren",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Doorverwijzen naar preview",
  },
  PlaylistsScreen: {
    Playlists: "Afspeellijsten",
    "New playlist": "Nieuwe afspeellijst",
    "{{count}} Playlist": "{{count}} Afspeellijst",
    "{{count}} Playlist_plural": "{{count}} Afspeellijsten",
    Name: "Naam",
    Tags: "Tags",
    "Loading playlists": "Afspeellijsten laden",
    "No playlists have been setup yet":
      "Er zijn nog geen afspeellijsten ingesteld",
    "Playlists allow you to join multiple configurations together.":
      "Afspeellijsten stellen je in staat om meerdere configuraties samen te voegen.",
    "No configurations selected": "Geen configuraties geselecteerd",
    "Add configuration": "Configuratie toevoegen",
    Cancel: "Annuleren",
    "Create playlist": "Afspeellijst maken",
    "Delete playlist": "Afspeellijst verwijderen",
    "Are you sure you want to delete this playlist?":
      "Weet je zeker dat je deze afspeellijst wilt verwijderen?",
    "Loading playlist": "Afspeellijst laden",
    "Save changes": "Wijzigingen opslaan",
    "Configuration already in playlist": "Configuratie al in afspeellijst",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "De geselecteerde configuratie maakt al deel uit van deze afspeellijst, wil je deze opnieuw toevoegen?",
    "Remove from playlist": "Verwijderen uit afspeellijst",
    Configuration: "Configuratie",
    "Number loops": "Aantal lussen",
    Active: "Actief",
    "Playlist updated": "Afspeellijst bijgewerkt",
    "Something went wrong": "Er is iets misgegaan",
    "Last updated": "Laatst bijgewerkt",
    "Digital Displays URL": "Digitale weergave-URL",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "U kunt deze hardcoded URL gebruiken om te integreren met systemen die onze QR-code installatie niet ondersteunen.",
    "Get hardcoded URL": "Hardcoded URL ophalen",
    l_layout_loop_description:
      "Mediabestanden zullen een bepaald aantal keren herhalen voordat ze naar de volgende configuratie gaan",
    media_only_loop_description:
      "Mediabestanden zullen een bepaald aantal keren herhalen voordat ze naar de volgende configuratie gaan",
    basic_loop_description:
      "Het schema zal een bepaald aantal keren herhalen voordat het naar de volgende configuratie gaat",
    multi_column_schedule_loop_description:
      "Het schema zal een bepaald aantal keren herhalen voordat het naar de volgende configuratie gaat",
    multi_day_schedule_loop_description:
      "Het schema zal een bepaald aantal keren herhalen voordat het naar de volgende configuratie gaat",
    schedule_only_loop_description:
      "Het schema zal een bepaald aantal keren herhalen voordat het naar de volgende configuratie gaat",
    "%future added value_loop_description": "Onbekend configuratietype",
    mvp_loop_description: "Onbekend configuratietype",
    timed_description:
      "Configuratie blijft voor een bepaalde tijd op het scherm voordat deze naar de volgende configuratie gaat",
    Looped: "In lus",
    Timed: "Getimed",
    "Duration (minutes)": "Duur (minuten)",
    "Search playlists...": "Zoek afspeellijsten...",
    "Edit configuration": "Configuratie bewerken",
    "New configuration": "Nieuwe configuratie",
    "Loading configuration": "Configuratie laden",
    "Add an existing configuration to the playlist":
      "Voeg een bestaande configuratie toe aan de afspeellijst",
    "Create a new configuration to add to the playlist":
      "Maak een nieuwe configuratie om toe te voegen aan de afspeellijst",
    "No playlists found for '{{searchText}}'":
      "Geen afspeellijsten gevonden voor '{{searchText}}'",
    "Last active at {{lastActiveAt}}": "Laatst actief om {{lastActiveAt}}",
    "Device count": "Aantal apparaten",
    "Loading screen devices": "Laden scherm apparaten",
    "Announcements with matching tags will be displayed on screen":
      "Aankondigingen met overeenkomende tags worden op het scherm weergegeven",
    "Hardcoded playlists and configurations are being depreciated, please use virtual devices.":
      "Gecodeerde afspeellijsten en configuraties zijn verouderd, gebruik alstublieft virtuele apparaten.",
    "Just like hardcoded playlists and configurations, a virtual device will provide you with a URL to be used with systems which do not support the QR setup.":
      "Net als gecodeerde afspeellijsten en configuraties, zal een virtueel apparaat u voorzien van een URL die kan worden gebruikt met systemen die de QR-configuratie niet ondersteunen.",
    "With a virtual device, you will be able to manage the active playlist or configuration without needing to update the third party system.":
      "Met een virtueel apparaat kunt u de actieve afspeellijst of configuratie beheren zonder het systeem van derden te hoeven bijwerken.",
  },
  PlaylistPicker: {
    Playlist: "Afspeellijst",
    "Clear playlist": "Afspeellijst wissen",
    "No playlist selected": "Geen afspeellijst geselecteerd",
    "Searching playlists": "Afspeellijsten zoeken",
    "No playlists found for '{{searchText}}'":
      "Geen afspeellijsten gevonden voor '{{searchText}}'",
    "No playlists found": "Geen afspeellijsten gevonden",
  },
  TableSearchField: {
    "Clear search text": "Zoektekst wissen",
  },
  SettingsScreen: {
    "Organisation settings": "Organisatie-instellingen",
    "Permission groups": "Permissiegroepen",
    "User access": "Gebruikerstoegang",
    "IP Addresses": "IP-adressen",
    "Loading settings": "Instellingen laden",
  },
  PermissionGroupsTab: {
    "New permission group": "Nieuwe permissiegroep",
    "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule":
      "Permissiegroepen stellen u in staat te specificeren op welke hub-profielen een gebruiker toegang heeft bij het instellen van een schema",
    "{{count}} Permission group": "{{count}} Toestemmingsgroep",
    "{{count}} Permission group_plural": "{{count}} Permissiegroepen",
    "Search permission groups...": "Zoek permissiegroepen...",
    Name: "Naam",
    "Last updated": "Laatst bijgewerkt",
    "Loading permission groups": "Permissiegroepen laden",
    "No permission groups yet": "Nog geen permissiegroepen",
    "No permission groups found for '{{searchText}}'":
      "Geen permissiegroepen gevonden voor '{{searchText}}'",
    "Permission groups allow you set what hub profiles a user has access to":
      "Permissiegroepen stellen u in staat in te stellen op welke hub-profielen een gebruiker toegang heeft",
    "{{count}} hub profile": "{{count}} hub--profiel",
    "{{count}} hub profile_plural": "{{count}} hub-profielen",
    "Permission group created": "Permissiegroep aangemaakt",
    "Something went wrong": "Er is iets misgegaan",
    "Loading permission group": "Permissiegroep laden",
    "Delete permission group": "Permissiegroep verwijderen",
    "Are you sure you want to delete this permission group?":
      "Weet u zeker dat u deze permissiegroep wilt verwijderen?",
    "Permission group deleted": "Permissiegroep verwijderd",
    "Permission group updated": "Permissiegroep bijgewerkt",
    "Save changes": "Wijzigingen opslaan",
    "Permission group name": "Naam van de permissiegroep",
    "Permitted hub profiles": "Toegestane hub-profielen",
    "Only permitted profiles will appear for users to select when managing display schedules":
      "Alleen toegestane profielen zullen beschikbaar zijn voor gebruikers bij het beheren van weergaveschema's",
    "Hub access": "Toegang tot Hub",
    "Nutrition access": "Toegang tot  Nutrition",
    Hub: "Hub",
    Nutrition: "Nutrition",
    "Accessible apps": "Toegankelijke apps",
  },
  UsersTab: {
    "Contact Teamworks support to add or remove users":
      "Neem contact op met Teamworks-ondersteuning om gebruikers toe te voegen of te verwijderen",
    "New user": "Nieuwe gebruiker",
    "This is a list of users who have access to Digital Displays":
      "Dit is een lijst van gebruikers die toegang hebben tot Digitale Schermen",
    "{{count}} User": "{{count}} Gebruiker",
    "{{count}} User_plural": "{{count}} Gebruikers",
    "Search users...": "Zoek gebruikers...",
    Name: "Naam",
    "Last updated": "Laatst bijgewerkt",
    "Loading users": "Gebruikers laden",
    "Loading user": "Gebruiker laden",
    "No users yet": "Nog geen gebruikers",
    "No users found for '{{searchText}}'":
      "Geen gebruikers gevonden voor '{{searchText}}'",
    "Super admin": "Superbeheerder",
    "{{count}} permission group": "{{count}} machtigingsgroep",
    "{{count}} permission group_plural": "{{count}} permissiegroepen",
    "User updated": "Gebruiker bijgewerkt",
    "Something went wrong": "Er is iets misgegaan",
    "Save changes": "Wijzigingen opslaan",
    superadmin_description:
      "Deze gebruiker is een superbeheerder. Hij heeft toegang tot alle hub-profielen, ongeacht de toegewezen permissiegroepen.",
    admin_description:
      "Deze gebruiker heeft alleen toegang tot de hub-profielen die aan hem zijn toegewezen via permissiegroepen.",
    "%future added value_description": "Onbekende rol",
  },
  ManageIpAddressesTab: {
    "Edit IP addresses": "IP-adressen bewerken",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Voor systemen die onze QR-instelling niet ondersteunen, kunnen deze toegestane IP-adressen worden gebruikt om toegang te krijgen tot hardcoded URL's.",
    "{{count}} Permitted IP address": "{{count}} Toegestaan IP-adres",
    "{{count}} Permitted IP address_plural": "{{count}} Toegestane IP-adressen",
    "No IP addresses": "Geen IP-adressen",
    "You have not added any permitted IP addresses yet":
      "Je hebt nog geen toegestane IP-adressen toegevoegd",
    "Permitted IP addresses updated": "Toegestane IP-adressen bijgewerkt",
    "Something went wrong": "Er is iets misgegaan",
    "Save changes": "Wijzigingen opslaan",
    "No permitted IPs yet": "Nog geen toegestane IP's",
    "Add another": "Nog een toevoegen",
  },
  PermissionGroupPicker: {
    "Add permission groups": "Permissiegroepen toevoegen",
    "No permission groups added": "Geen permissiegroepen toegevoegd",
    "Show {{count}} more": "Toon {{count}} meer",
    "Clear search text": "Zoektekst wissen",
    "Search permission groups...": "Zoek permissiegroepen...",
    "Loading permission groups": "Permissiegroepen laden",
    "{{count}} hub profile": "{{count}} hub-profiel",
    "{{count}} hub profile_plural": "{{count}} hub-profielen",
    "Remove permission group": "Permissiegroep verwijderen",
    "No users found": "Geen gebruikers gevonden",
    "We couldn't find any users matching '{{searchText}}'":
      "We konden geen gebruikers vinden die overeenkomen met '{{searchText}}'",
    "No permission groups found": "Geen permissiegroepen gevonden",
    "Confirm selection": "Bevestig selectie",
  },
  TableProvider: {
    "{{count}} Selected": "{{count}} Geselecteerd",
  },
  TagPicker: {
    "Add tags": "Tags toevoegen",
    Clear: "Wissen",
    "Searching tags": "Tags zoeken",
    "No tags selected": "Geen tags geselecteerd",
    "Remove tag": "Tag verwijderen",
    "No tags found matching '{{searchText}}'":
      "Geen tags gevonden die overeenkomen met '{{searchText}}'",
    "Something went wrong": "Er is iets misgegaan",
    "Create tag '{{tagName}}'": "Tag '{{tagName}}' aanmaken",
    "Start typing to create a new tag":
      "Begin met typen om een nieuw tag aan te maken",
    "Delete tag": "Tag verwijderen",
    "Are you sure you want to delete the tag '{{tagName}}'?":
      "Weet u zeker dat u de tag '{{tagName}}' wilt verwijderen?",
    "Tag deleted": "Tag verwijderd",
  },
};
