/**
 * @generated SignedSource<<a63c8c57cc616355df9429799a61d1d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccessibleWebApp = "academics" | "ams" | "arms" | "communities" | "hub" | "inflcr" | "nutrition" | "pathways" | "pulse" | "strength" | "trac" | "tv" | "wallet" | "%future added value";
export type AppSwitcherQuery$variables = {
  app: AccessibleWebApp;
};
export type AppSwitcherQuery$data = {
  readonly currentUser: {
    readonly webAppRedirectUri: string | null | undefined;
  };
};
export type AppSwitcherQuery = {
  response: AppSwitcherQuery$data;
  variables: AppSwitcherQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "app"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "app",
      "variableName": "app"
    }
  ],
  "kind": "ScalarField",
  "name": "webAppRedirectUri",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppSwitcherQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppSwitcherQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6988e4ca697cf73b43ac108e56d1fee0",
    "id": null,
    "metadata": {},
    "name": "AppSwitcherQuery",
    "operationKind": "query",
    "text": "query AppSwitcherQuery(\n  $app: AccessibleWebApp!\n) {\n  currentUser {\n    webAppRedirectUri(app: $app)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "df25bd848f9f28e3793d693f67b60d3c";

export default node;
