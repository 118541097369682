import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { CurrentProviderQuery } from "./__generated__/CurrentProviderQuery.graphql";
import { errorReporting } from "../helpers/errorReporting";
import { featureFlags } from "../helpers/featureFlags";

const CurrentContext = createContext<CurrentProviderQuery["response"]>(null!);

export const CurrentProvider = (props: PropsWithChildren) => {
  const data = useLazyLoadQuery<CurrentProviderQuery>(
    graphql`
      query CurrentProviderQuery {
        currentUser {
          id
          fullName
          initials
          avatarUrl
          accessibleWebApps
          role
          nutritionAccess
          hubAccess
        }
        currentOrganisation {
          id
          name
          navbarBottomColor
          navbarTopColor
          navbarImageUrl
          permittedIpAddresses
          activeFeatureFlags
        }
      }
    `,
    {},
  );

  useEffect(() => {
    errorReporting.setUserData(
      data.currentUser.id,
      data.currentOrganisation.id,
    );
  }, [data]);

  return (
    <CurrentContext.Provider value={data}>
      {props.children}
    </CurrentContext.Provider>
  );
};

export function useCurrentUser() {
  const context = useContext(CurrentContext);
  if (context == null) {
    throw new Error("Used useCurrentUser before user ready");
  }
  return context.currentUser;
}

export function useCurrentOrganisation() {
  const context = useContext(CurrentContext);
  if (context == null) {
    throw new Error("Used useCurrentOrganisation before organisation ready");
  }
  return context.currentOrganisation;
}

export function useFeatureFlagEnabled(flag: keyof typeof featureFlags) {
  return useCurrentOrganisation().activeFeatureFlags.includes(
    featureFlags[flag],
  );
}
