import React, { Suspense, useEffect } from "react";
import { PropsWithChildren } from "react";
import { LoadingScreen } from "../components/LoadingScreen";
import { graphql, useLazyLoadQuery } from "react-relay";
import { PreviewsProviderQuery } from "./__generated__/PreviewsProviderQuery.graphql";
import { config } from "../config";
import { useTranslation } from "react-i18next";

export const PreviewsProvider = ({ children }: PropsWithChildren) => {
  const { searchParams } = new URL(window.location.href);
  const previewBranch = searchParams.get("previewBranch");

  if (previewBranch != null) {
    return (
      <Suspense fallback={<LoadingState />}>
        <RedirectToPreview previewBranch={previewBranch} />
      </Suspense>
    );
  }

  return children;
};

const RedirectToPreview = ({ previewBranch }: { previewBranch: string }) => {
  const {
    currentUser: { axleRedirectToken },
  } = useLazyLoadQuery<PreviewsProviderQuery>(
    graphql`
      query PreviewsProviderQuery {
        currentUser {
          axleRedirectToken
        }
      }
    `,
    {},
    { fetchPolicy: "network-only" },
  );

  useEffect(() => {
    if (axleRedirectToken == null) {
      throw new Error("Preview redirect token not found");
    }

    const origin = config.isStaging
      ? config.stagingPreviewsOrigin
      : config.productionPreviewsOrigin;

    window.location.replace(
      `https://${previewBranch.replaceAll("_", "-")}.${origin}?axleToken=${axleRedirectToken}`,
    );
  }, [axleRedirectToken, previewBranch]);

  return <LoadingState />;
};

const LoadingState = () => {
  const { t } = useTranslation("PreviewsProvider");
  return <LoadingScreen message={t("Redirecting to preview")} />;
};
