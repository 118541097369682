/**
 * @generated SignedSource<<e7752c5b0ae0441123ec0b3afaf52eea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PreviewsProviderQuery$variables = Record<PropertyKey, never>;
export type PreviewsProviderQuery$data = {
  readonly currentUser: {
    readonly axleRedirectToken: string | null | undefined;
  };
};
export type PreviewsProviderQuery = {
  response: PreviewsProviderQuery$data;
  variables: PreviewsProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "axleRedirectToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewsProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PreviewsProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebff3ace9414fa62261f847ce0601a6b",
    "id": null,
    "metadata": {},
    "name": "PreviewsProviderQuery",
    "operationKind": "query",
    "text": "query PreviewsProviderQuery {\n  currentUser {\n    axleRedirectToken\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "be82ac4a1551c2f146aadace2ed9a7d8";

export default node;
