import React, { PropsWithChildren, useMemo } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  createTheme,
} from "@mui/material";
import { useCurrentOrganisation } from "./CurrentProvider";
import { colors, inter } from "../helpers/theme";
import { colord, extend } from "colord";
import a11yPlugin from "colord/plugins/a11y";

extend([a11yPlugin]);

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface DefaultTheme extends Theme {}
}

export const ThemeProvider = (props: PropsWithChildren) => {
  const org = useCurrentOrganisation();

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: inter,
        },
        palette: {
          primary: {
            main: org.navbarTopColor,
          },
          secondary: {
            main: org.navbarBottomColor,
            contrastText: colord(org.navbarBottomColor)
              .darken(0.2)
              .isReadable(colors.white, { size: "large" })
              ? org.navbarBottomColor
              : org.navbarTopColor,
          },
        },
      }),
    [org],
  );

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};
