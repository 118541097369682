import React from "react";
import { FlexProps, Row } from "./Flex";
import { Spacer } from "./Spacer";

export const IconAdornment = (props: FlexProps) => (
  <Row>
    {props.children}
    <Spacer size="smallest" />
  </Row>
);
