import React, { HTMLAttributes, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { ScreenSize, useScreenSize } from "../providers/ScreenSizeProvider";
import {
  Spacing,
  borderRadius,
  colors,
  fontSize,
  iconSize,
  spacing,
} from "../helpers/theme";
import { MuiIcon } from "../helpers/types";
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  CloseRounded,
  ReportProblemRounded,
  SearchRounded,
} from "@mui/icons-material";
import { Column, Row } from "./Flex";
import { AppText, HelperText, Label } from "./Typography";
import { Spacer } from "./Spacer";
import { LoadingScreen } from "./LoadingScreen";
import { Button, ButtonProps } from "./Button";
import { SortDirection } from "../screens/Devices/__generated__/DevicesTab_DevicesQuery.graphql";
import { useTableContext } from "../providers/TableProvider";
import { TextInput } from "./TextInput";
import { IconAdornment } from "./IconAdornment";
import { Tooltip } from "./Tooltip";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const useTableStyles = makeStyles({
  table: {
    border: `1px solid ${colors.gray3}`,
    borderRadius: borderRadius.medium,
    overflow: "hidden",
  },
  tableHead: {
    height: 44,
  },
  tableBody: {
    "& > tr:not(:last-child) > td": {
      borderBottom: `1px solid ${colors.gray3}`,
    },
    "& > tr:hover > td:not(.no-hover)": {
      backgroundColor: colors.gray2,
    },
  },
  tableBordered: {
    "& > tr > td:not(:last-child)": {
      borderRight: `1px solid ${colors.gray3}`,
    },
  },
  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: `${spacing.smaller}px ${spacing.medium}px`,
    fontSize: fontSize.small,
    fontWeight: 400,
    color: colors.black,
  },
  tableHeaderCell: {
    fontWeight: 600,
    color: colors.gray6,
    borderBottom: `1px solid ${colors.gray3}`,
    backgroundColor: colors.gray1,
  },
  tableHeaderCellDark: {
    fontWeight: 600,
    color: colors.black,
    backgroundColor: colors.gray2,
  },
  emptyStateIconWrapper: {
    display: "block",
    padding: spacing.smaller,
    backgroundColor: colors.black,
    color: colors.white,
    borderRadius: 1000,
    boxSizing: "content-box",
  },
  emptyStateIcon: {
    width: iconSize.regular,
    height: iconSize.regular,
  },
  sortableField: {
    userSelect: "none",
    cursor: "pointer",
    gap: spacing.smallest,
    display: "flex",
    alignItems: "center",
  },
});

type TableCellProps = HTMLAttributes<HTMLTableCellElement> & {
  screenSizes?: ScreenSize[];
  colSpan?: number;
  paddingHorizontal?: Spacing;
};

type SortableProps<T> = {
  sort: { field: T; direction: SortDirection };
  onSort: (field: T) => void;
};

type SortableFieldProps<T> =
  | { sortable?: false }
  | ({ sortable: true; field: T } & SortableProps<T>);

type TableHeaderCellProps<T> = TableCellProps & {
  dark?: boolean;
} & SortableFieldProps<T>;

export function useSortableTable<T>(defaults: {
  field: T;
  direction: "asc" | "desc";
}): SortableProps<T> {
  const [sort, setSort] = useState(defaults);
  return {
    sort,
    onSort: (field: T) => {
      setSort((s) => ({
        field,
        direction:
          s.field === field
            ? s.direction === "asc"
              ? "desc"
              : "asc"
            : (field as string).endsWith("At")
              ? "desc"
              : "asc",
      }));
    },
  };
}

export function sortableFieldProps<T>(
  { sort, onSort }: SortableProps<T>,
  field: T,
): SortableFieldProps<T> {
  return {
    sortable: true,
    field,
    sort,
    onSort: () => onSort(field),
  };
}

export const Table = ({
  className,
  ...props
}: HTMLAttributes<HTMLTableElement>) => {
  const classes = useTableStyles();
  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      className={classNames(classes.table, className)}
      {...props}
    />
  );
};

export const TableHead = ({
  className,
  bordered,
  ...props
}: HTMLAttributes<HTMLTableSectionElement> & {
  bordered?: boolean;
}) => {
  const classes = useTableStyles();
  return (
    <thead
      className={classNames(
        classes.tableHead,
        bordered ? classes.tableBordered : undefined,
        className,
      )}
      {...props}
    />
  );
};

export const TableHeader = ({
  cellCount,
  searchPlaceholder,
}: {
  cellCount: number;
  searchPlaceholder: string;
}) => {
  const { title, searchText, setSearchText } = useTableContext();
  const { t } = useTranslation("TableSearchField");
  return (
    <TableHead>
      <TableRow>
        <TableHeaderCell dark colSpan={cellCount}>
          {title}
        </TableHeaderCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={cellCount} paddingHorizontal="smaller">
          <TextInput
            name="search"
            size="small"
            fullWidth
            maxLength={100}
            value={searchText}
            placeholder={searchPlaceholder}
            onChange={(ev) => setSearchText(ev.currentTarget.value)}
            InputProps={{
              startAdornment: (
                <IconAdornment>
                  <SearchRounded style={{ color: colors.gray5 }} />
                </IconAdornment>
              ),
              endAdornment:
                searchText.length > 0 ? (
                  <Tooltip placement="left" title={t("Clear search text")}>
                    <IconButton size="small" onClick={() => setSearchText("")}>
                      <CloseRounded style={{ color: colors.gray5 }} />
                    </IconButton>
                  </Tooltip>
                ) : undefined,
            }}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TableBody = ({
  className,
  bordered,
  ...props
}: HTMLAttributes<HTMLTableSectionElement> & {
  bordered?: boolean;
}) => {
  const classes = useTableStyles();
  return (
    <tbody
      className={classNames(
        classes.tableBody,
        bordered ? classes.tableBordered : undefined,
        className,
      )}
      {...props}
    />
  );
};

export const TableRow = (props: HTMLAttributes<HTMLTableRowElement>) => {
  return <tr {...props} />;
};

export const TableCell = ({
  screenSizes,
  className,
  paddingHorizontal,
  style,
  children,
  ...props
}: TableCellProps) => {
  const screenSize = useScreenSize();
  const classes = useTableStyles();
  if (screenSizes != null && !screenSizes.includes(screenSize)) {
    return null;
  }

  return (
    <td
      className={classNames(classes.tableCell, className)}
      style={{
        ...(paddingHorizontal == null
          ? {}
          : {
              paddingLeft: spacing[paddingHorizontal],
              paddingRight: spacing[paddingHorizontal],
            }),
        ...style,
      }}
      {...props}
    >
      {typeof children === "string" ? <Label>{children}</Label> : children}
    </td>
  );
};

function useSortableProps<T>(props: TableHeaderCellProps<T>): {
  sortableProps: SortableFieldProps<T>;
  rest: TableHeaderCellProps<T>;
} {
  if (props.sortable) {
    const { sortable, sort, onSort, field, ...rest } = props;
    return { sortableProps: { sortable, sort, onSort, field }, rest };
  }

  const { sortable, ...rest } = props;
  return { sortableProps: { sortable }, rest };
}

export function TableHeaderCell<T>({
  className,
  dark,
  children,
  ...props
}: TableHeaderCellProps<T>) {
  const classes = useTableStyles();
  const { sortableProps, rest } = useSortableProps(props);
  return (
    <TableCell
      className={classNames(
        dark ? classes.tableHeaderCellDark : classes.tableHeaderCell,
        className,
      )}
      {...rest}
    >
      <span
        className={sortableProps.sortable ? classes.sortableField : undefined}
        onClick={
          sortableProps.sortable
            ? () => sortableProps.onSort(sortableProps.field)
            : undefined
        }
      >
        {children}
        {sortableProps.sortable &&
          sortableProps.sort.field === sortableProps.field &&
          (sortableProps.sort.direction === "asc" ? (
            <ArrowDropUpRounded />
          ) : (
            <ArrowDropDownRounded />
          ))}
      </span>
    </TableCell>
  );
}

export const TableEmptyState = ({
  colSpan,
  ...props
}: {
  title: string;
  body?: string;
  colSpan?: number;
  Icon?: MuiIcon;
  actions?: ButtonProps[];
}) => {
  const classes = useTableStyles();
  const Icon = props.Icon ?? ReportProblemRounded;
  const actions = props.actions ?? [];

  return (
    <TableBody>
      <TableRow>
        <TableCell
          className="no-hover"
          colSpan={colSpan ?? 1}
          style={{ maxWidth: "70vw" }}
        >
          <Column
            paddingVertical="gigantic"
            alignItems="center"
            style={{ textWrap: "wrap", textAlign: "center" }}
          >
            <span className={classes.emptyStateIconWrapper}>
              <Icon
                className={classes.emptyStateIcon}
                sx={{ width: iconSize.regular, height: iconSize.regular }}
              />
            </span>
            <Spacer size="small" />
            <AppText style={{ flexWrap: "wrap" }}>{props.title}</AppText>
            {props.body != null && (
              <>
                <Spacer size="tiny" />
                <HelperText style={{ flexWrap: "wrap" }}>
                  {props.body}
                </HelperText>
              </>
            )}
            {actions.length > 0 && (
              <Row
                gap="small"
                wrap="wrap"
                justifyContent="center"
                style={{ marginTop: spacing.medium }}
              >
                {actions.map((action, i) => (
                  <Button {...action} key={i} />
                ))}
              </Row>
            )}
          </Column>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export const TableLoadingState = (props: {
  message: string;
  colSpan?: number;
}) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell className="no-hover" colSpan={props.colSpan ?? 1}>
          <LoadingScreen message={props.message} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
