import { ConnectionHandler } from "react-relay";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { config } from "../config";
import { Json, Region } from "./types";
import { random } from "lodash";
import { baseUrlForRegion } from "./baseUrlForRegion";

type EnvironmentArgs = {
  secureToken: string;
  orgId: string;
  region: Region;
  profileId: string;
  onNoAuth: () => void;
};

export const createRelayEnvironment = (args: EnvironmentArgs) => {
  const store = new Store(new RecordSource());
  if (config.isDevelopment) {
    // @ts-expect-error useful for debugging
    window.__store = store;
    // @ts-expect-error useful for debugging
    window.__connectionHandler = ConnectionHandler;
  }

  return new Environment({
    network: Network.create(sendRequest(args)),
    store,
  });
};

const delay = (durationMilliseconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), durationMilliseconds);
  });
};

const sendRequest =
  ({ secureToken, orgId, region, profileId, onNoAuth }: EnvironmentArgs) =>
  async (
    params: { operationKind: string; name: string; text?: string | null },
    variables: Json,
  ) => {
    if (config.isDevelopment) {
      await delay(random(100, 300));
    }

    const response = await fetch(`${baseUrlForRegion(region)}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${secureToken}`,
        "Org-Id": orgId,
        "Profile-Id": profileId,
        "Operation-Name": params.name,
      },
      body: JSON.stringify({
        query: params.text,
        variables,
      }),
    });

    const body = await response.text();

    if (config.isDevelopment) {
      console.debug(response.status, body);
    }

    if (response.status === 401) {
      onNoAuth();
      return;
    }

    if (!response.ok) {
      throw new Error(body);
    }

    return JSON.parse(body);
  };
